import { ThunkDispatch } from "@reduxjs/toolkit";

import {
  addMessage,
  createMessage,
  IMessage,
  INewMessage,
  INewMessageBotmaker,
  INewMessageExternalappWpp,
  MessageType,
  replyTime,
} from "./../../../../slices/messages.slice";

import {
  ChatStatus,
  ChatType,
  createChat,
  IChat,
  openChat,
  selectChat,
  selectHistoyContact,
} from "../../../../slices/chats.slice";

import { IUser } from "../../../../slices/users.slice";

import { messagesService, whatsappService } from "../../../../services";

import { IChatTemplate } from "./@types";

interface ITemplate {
  contact: IUser;
  user: IUser;
  chatTemplate: IChatTemplate;
}

export class TemplateService {
  private user: IUser;
  private contact: IUser;
  private chatTemplate: IChatTemplate;
  private dispatch: ThunkDispatch<any, any, any>;

  constructor(
    { contact, user, chatTemplate }: ITemplate,
    dispatch: ThunkDispatch<any, any, any>
  ) {
    this.user = user;
    this.contact = contact;
    this.chatTemplate = chatTemplate;
    this.dispatch = dispatch;
  }

  public async hasActiveChat(): Promise<any> {
    const { payload } = await this.dispatch(
      replyTime({
        contactId: this.contact._id!,
        externalapp: this.chatTemplate.externalapp,
      })
    );

    console.log('service pay', {payload});
    

    if (Object.keys(payload).length) {
      const action = await this.dispatch(selectChat({ _id: payload._id }));
      if (action.payload) {
        this.dispatch(selectHistoyContact(null));
        this.dispatch(openChat(action.payload));
      }

      return payload;
    }

    return {};
  }

  public async createNewChat(type: ChatType): Promise<IChat | unknown> {
    const { payload } = await this.dispatch(
      createChat({
        users: [this.contact?._id!, this.user?._id!],
        status: ChatStatus.WAITREPLY,
        type: type,
        team: this.chatTemplate.chat.team,
        externalapp: this.chatTemplate.externalapp,
        externalcodephone: JSON.parse(this.chatTemplate.template).phone,
      })
    );

    return payload;
  }

  async createMessage(chat: IChat | unknown): Promise<IMessage> {
    const newMessage: INewMessage = {
      chat: chat as IChat,
      content: this.chatTemplate.message.content,
      files: this.chatTemplate.message?.files || [],
      parent: null,
      referenceid: "",
      origin: this.user?._id,
    };

    const actionMessage = await messagesService.create(newMessage);

    return actionMessage;
  }

  async createWppMessage(message: IMessage, chat: IChat | any): Promise<any> {
    message.chat = chat as IChat;
    this.dispatch(addMessage(message));

    const _newMsg: IMessage = message;

    const newMessageWpp: INewMessageExternalappWpp | INewMessageBotmaker = {
      chat_id: chat._id,
      message_id: _newMsg._id,
      messaging_product: "whatsapp",
      recipient_type: "individual",
      to: this.contact.phone!,
      contact: this.contact,
      team: this.chatTemplate.chat.team,
      type: "template",
      externalapp: this.chatTemplate.externalapp,
      template: JSON.parse(this.chatTemplate.template),
      externalcodephone: JSON.parse(this.chatTemplate.template).phone,
    };

    const actionMessageWpp = await whatsappService.createWpp(newMessageWpp);

    return actionMessageWpp;
  }

  async sendInternalMsg(chat: IChat | unknown): Promise<any> {
    const _newInternalMessage: INewMessage = {
      chat: chat as IChat,
      content: "Aguardando retorno do contato",
      files: [],
      parent: null,
      referenceid: "",
      origin: this.user?._id,
      internal: true,
      read: true,
      type: MessageType.USER,
    };

    const action = await this.dispatch(createMessage(_newInternalMessage));

    return action;
  }
}
