/* eslint-disable no-restricted-syntax */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { ChangeEvent, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { ChatType, IChat } from "../../../slices/chats.slice";
import { IAcceptedFiles } from "../../models/interfaces/acceptedfile.interface";
import FileComponent from "./File.component";
import findInObject from "../../utils/findInObject";
import customToast from "../../utils/customToast";
import { IToastType } from "../../models/types/Toast.type";
import PopupComponent from "../Popup.component";
import { useAppDispatch, useAppSelector } from "../../../hooks/redux/hooks";
import { reselectTemplateFiles } from "../../../slices/messages.slice";

const FilesArea = ({
  chat,
  type,
  single,
  files,
  importAction,
  hideLabel,
  setFiles,
  extraClass,
}: {
  chat?: IChat;
  type?: string;
  single?: boolean;
  importAction?: boolean;
  hideLabel?: boolean;
  files: IAcceptedFiles;
  setFiles: React.Dispatch<React.SetStateAction<IAcceptedFiles>>;
  extraClass?: string;
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { selectedTemplateFiles } = useAppSelector((state) => state.messages);
  const accept =
    "application/pdf, text/html, text/plain, text/csv, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/gzip, application/vnd.oasis.opendocument.presentation, application/vnd.oasis.opendocument.spreadsheet, application/vnd.oasis.opendocument.text, application/vnd.ms-powerpoint, application/vnd.openxmlformats-officedocument.presentationml.presentation, application/vnd.rar, application/rtf, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/x-zip-compressed, application/x-7z-compressed, application/x-rar-compressed, image/*, video/*, audio/*";

  const acceptImport =
    "text/csv, application/vnd.oasis.opendocument.spreadsheet, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";

  const acceptWhatsApp =
    "image/jpeg, image/png, audio/aac, audio/mpeg, audio/ogg, video/mp4, video/3gpp, image/webp, text/plain, application/pdf, application/vnd.ms-powerpoint, application/msword, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.openxmlformats-officedocument.presentationml.presentation, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";

  const acceptArray = [
    "application/pdf",
    "text/html",
    "text/plain",
    "text/csv",
    "application/msword",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    "application/gzip",
    "application/vnd.oasis.opendocument.presentation",
    "application/vnd.oasis.opendocument.spreadsheet",
    "application/vnd.oasis.opendocument.text",
    "application/vnd.ms-powerpoint",
    "application/vnd.openxmlformats-officedocument.presentationml.presentation",
    "application/vnd.rar",
    "application/rtf",
    "application/vnd.ms-excel",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    "application/x-zip-compressed",
    "application/x-7z-compressed",
    "application/x-rar-compressed",
    "image/*",
    "video/*",
    "audio/*",
  ];

  const acceptImportArray = [
    "text/csv",
    "application/vnd.oasis.opendocument.spreadsheet",
    "application/vnd.ms-excel",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  ];

  const acceptWhatsAppArray = [
    "image/jpeg",
    "image/png",
    " audio/aac",
    "audio/mpeg",
    "audio/ogg",
    "video/mp4",
    "video/3gpp",
    "image/webp",
    "text/plain",
    "application/pdf",
    "application/vnd.ms-powerpoint",
    "application/msword",
    "application/vnd.ms-excel",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    "application/vnd.openxmlformats-officedocument.presentationml.presentation",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  ];

  const acceptWhatsAppImageArray = ["image/jpeg", "image/png", "image/webp"];
  const acceptWhatsAppImage = "image/jpeg, image/png, image/webp";
  const acceptWhatsAppVideoArray = ["video/mp4", "video/3gpp"];
  const acceptWhatsAppVideo = "video/mp4, video/3gpp";
  const acceptWhatsAppDocumentArray = [
    "audio/aac",
    "audio/mpeg",
    "audio/ogg",
    "text/plain",
    "application/pdf",
    "application/vnd.ms-powerpoint",
    "application/msword",
    "application/vnd.ms-excel",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    "application/vnd.openxmlformats-officedocument.presentationml.presentation",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  ];
  const acceptWhatsAppDocument =
    "audio/aac, audio/mpeg, audio/ogg, text/plain, application/pdf, application/vnd.ms-powerpoint, application/msword, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.openxmlformats-officedocument.presentationml.presentation, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";

  const handleFilesChange = (event: ChangeEvent<HTMLInputElement>) => {
    const acceptedFiles: IAcceptedFiles = [];
    Array.from(event.target.files || []).map((file) => {
      if (
        (acceptArray.includes(file.type) ||
          file.type.includes("image/") ||
          file.type.includes("video/") ||
          file.type.includes("audio/")) &&
        ((chat?.type === ChatType.WHATSAPP &&
          acceptWhatsAppArray.includes(file.type)) ||
          chat?.type !== ChatType.WHATSAPP)
      ) {
        if (file.size > 20000000) {
          customToast({
            type: IToastType.WARNING,
            message: t("toast.error.files.file-too-large"),
          });
          return false;
        }
        acceptedFiles.push(file);
        return true;
      }
      customToast({
        type: IToastType.WARNING,
        message: t("toast.error.files.unsupported"),
      });
      return false;
    });
    window.URL = window.URL || window.webkitURL;
    const _files: File[] = [];
    Array.from(acceptedFiles || []).forEach((file) => {
      _files.push(
        Object.assign(file, {
          preview: window.URL.createObjectURL(file),
        })
      );
      return true;
    });
    dispatch(
      reselectTemplateFiles({
        files: [...selectedTemplateFiles, ..._files],
        currentChanged: true,
      })
    );
    setFiles((oldArray: IAcceptedFiles) => [...oldArray, ..._files]);
  };

  const validateFile = ({
    condition,
    file,
  }: {
    condition: boolean;
    file: File;
  }) => {
    const _file = new File([file], file.name, {
      type: file.type,
      lastModified: file.lastModified,
    });
    window.URL = window.URL || window.webkitURL;
    if (condition) {
      if (file.size > 20000000) {
        customToast({
          type: IToastType.WARNING,
          message: t("toast.error.files.file-too-large"),
        });
        dispatch(
          reselectTemplateFiles({
            files: [],
            currentChanged: true,
          })
        );
        setFiles([]);
        return false;
      }
      dispatch(
        reselectTemplateFiles({
          files: [
            Object.assign(_file, {
              preview: window.URL.createObjectURL(_file),
            }),
          ],
          currentChanged: true,
        })
      );
      setFiles([
        Object.assign(_file, {
          preview: window.URL.createObjectURL(_file),
        }),
      ]);
      return true;
    }
    customToast({
      type: IToastType.WARNING,
      message: t("toast.error.files.unsupported"),
    });
    dispatch(
      reselectTemplateFiles({
        files: [],
        currentChanged: true,
      })
    );
    setFiles([]);
    return false;
  };

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    Array.from(event.target.files || []).map((file) => {
      if (typeof type !== "undefined") {
        switch (type) {
          case "IMAGE":
            validateFile({
              condition: acceptWhatsAppImageArray.includes(file.type),
              file,
            });
            break;
          case "VIDEO":
            validateFile({
              condition: acceptWhatsAppVideoArray.includes(file.type),
              file,
            });
            break;
          case "DOCUMENT":
            validateFile({
              condition: acceptWhatsAppDocumentArray.includes(file.type),
              file,
            });
            break;
          default:
            return false;
        }
      }
      if (typeof importAction !== "undefined" && importAction) {
        const _file = new File([file], file.name, {
          type: file.type,
          lastModified: file.lastModified,
        });
        window.URL = window.URL || window.webkitURL;
        if (acceptImportArray.includes(file.type)) {
          if (file.size > 20000000) {
            customToast({
              type: IToastType.WARNING,
              message: t("toast.error.files.file-too-large"),
            });
            dispatch(
              reselectTemplateFiles({
                files: [],
                currentChanged: true,
              })
            );
            setFiles([]);
            return false;
          }
          dispatch(
            reselectTemplateFiles({
              files: [
                Object.assign(_file, {
                  preview: window.URL.createObjectURL(_file),
                }),
              ],
              currentChanged: true,
            })
          );
          setFiles([
            Object.assign(_file, {
              preview: window.URL.createObjectURL(_file),
            }),
          ]);
          return true;
        }
        customToast({
          type: IToastType.WARNING,
          message: t("toast.error.files.unsupported"),
        });
        dispatch(
          reselectTemplateFiles({
            files: [],
            currentChanged: true,
          })
        );
        setFiles([]);
        return false;
      }
      return false;
    });
  };

  const removeFile = (index: number) => {
    const _files = files.slice();
    _files.splice(index, 1);
    dispatch(
      reselectTemplateFiles({
        files: _files,
        currentChanged: true,
      })
    );
    setFiles(_files);
  };

  useEffect(() => {
    const inputFile = document.getElementById(
      "custom-file"
    ) as HTMLInputElement;
    if (inputFile) {
      const FileListItems = (_files: IAcceptedFiles) => {
        const fileList =
          new ClipboardEvent("").clipboardData || new DataTransfer();
        for (const _file of _files) {
          fileList.items.add(new File([_file], _file.name));
        }
        return fileList.files;
      };

      const _files = [];
      for (const file of files) {
        if (findInObject(inputFile.files, "name", file.name) !== "undefined") {
          _files.push(file);
        }
      }
      inputFile.files = FileListItems(_files);
    }
  }, [files]);

  const getAccept = () => {
    if (typeof type !== "undefined") {
      switch (type) {
        case "IMAGE":
          return acceptWhatsAppImage;
        case "VIDEO":
          return acceptWhatsAppVideo;
        case "DOCUMENT":
          return acceptWhatsAppDocument;
        default:
          return "";
      }
    }
    if (typeof importAction !== "undefined" && importAction) {
      return acceptImport;
    }
    if (
      typeof chat !== "undefined" &&
      typeof chat?.type !== "undefined" &&
      chat?.type === ChatType.WHATSAPP
    ) {
      return acceptWhatsApp;
    }
    return accept;
  };

  return (
    <div className={extraClass}>
      {typeof hideLabel === "undefined" || !hideLabel ? (
        <div className="flex">
          {typeof importAction !== "undefined" && importAction ? (
            <PopupComponent
              className="flex items-center mb-1"
              label="Preencha e selecione o arquivo para importação"
              required
              content={
                <h1 className="font-semibold text-[12px]">
                  Tamanho máximo do arquivo: 20MB
                </h1>
              }
            />
          ) : (
            <label className="mb-1">Anexos</label>
          )}
        </div>
      ) : null}
      <input
        id="custom-file"
        className="text-[10px]"
        type="file"
        multiple={typeof single !== "undefined" ? !single : true}
        accept={getAccept()}
        onChange={
          !single || typeof single === "undefined"
            ? handleFilesChange
            : handleFileChange
        }
      />
      {files.length > 0 ? (
        <div
          id="sidemenu"
          className={`mt-4 max-h-[150px] 2xl:max-h-[350px] overflow-y-auto ${extraClass}`}
        >
          {files.map((file, index) => (
            <FileComponent
              key={index.toString() + file.name}
              remove={() => removeFile(index)}
              file={file}
            />
          ))}
        </div>
      ) : null}
    </div>
  );
};

FilesArea.defaultProps = {
  chat: undefined,
  extraClass: undefined,
  type: undefined,
  single: undefined,
  hideLabel: undefined,
  importAction: undefined,
};

export default FilesArea;
