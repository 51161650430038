/* eslint-disable no-plusplus */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-nested-ternary */
import EmojiPicker, { IEmojiData } from "emoji-picker-react";
import React, { useState, ChangeEvent, useRef, useEffect } from "react";
import { useReactMediaRecorder } from "react-media-recorder";
import { t } from "i18next";
import axios from "axios";
import { useAppDispatch, useAppSelector } from "../../../../hooks/redux/hooks";
import { Files } from "../../../../shared/models/interfaces/file.interface";
import {
  createMessage,
  IMessage,
  INewMessage,
  INewMessageBotmaker,
  INewMessageWpp,
  SenderType,
  sendMessageBotmaker,
  sendMessageWpp,
  triggerChatInactive,
  typingMessage,
} from "../../../../slices/messages.slice";
import {
  IAcceptedFiles,
  IAcceptedFile,
} from "../../../../shared/models/interfaces/acceptedfile.interface";
import { authService, teamsService } from "../../../../services";
import {
  ChatStatus,
  ChatType,
  // IChat,
  // blockChat,
  // chatIsBlocked,
  closeChat,
  removeFromBacklog,
  reselectChat,
  updateChat,
} from "../../../../slices/chats.slice";
import { IUser, RoleUser, UserType } from "../../../../slices/users.slice";
import PrimaryOptions from "./PrimaryOptions.component";
import SecondaryOptions from "./SecondaryOptions.component";
import FormButton from "../../../../shared/components/Buttons/FormButton.component";
import removeDuplicates from "../../../../shared/utils/removeDuplicates";
import Button from "../../../../shared/components/Buttons/Button.component";
import customToast from "../../../../shared/utils/customToast";
import { IToastType } from "../../../../shared/models/types/Toast.type";
import useContactLogout from "../../../../hooks/useContactLogout";
import useUserIsAdmin from "../../../../hooks/useUserIsAdmin";
import RelationModal from "../../../../shared/components/Modals/RelationModal.component";
import useFormatText from "../../../../hooks/useFormatText";
import stripTags from "../../../../shared/utils/stripTags";

const SendBox = ({
  message,
  setMessage,
  thumbs,
  setThumbs,
  isReplying,
  setIsReplying,
  isFocused,
  setIsFocused,
  messageReplied,
  setMessageReplied,
  keyword,
  setKeyword,
  setMessageSent,
  setToggleList,
  widgetType,
}: {
  message?: string;
  setMessage: React.Dispatch<React.SetStateAction<string>>;
  thumbs: IAcceptedFiles;
  setThumbs: React.Dispatch<React.SetStateAction<IAcceptedFiles>>;
  isReplying: boolean;
  setIsReplying: React.Dispatch<React.SetStateAction<boolean>>;
  isFocused: boolean;
  setIsFocused: React.Dispatch<React.SetStateAction<boolean>>;
  messageReplied: IMessage | null;
  setMessageReplied: React.Dispatch<React.SetStateAction<IMessage | null>>;
  keyword: string;
  setKeyword: React.Dispatch<React.SetStateAction<string>>;
  setMessageSent: React.Dispatch<React.SetStateAction<boolean>>;
  setToggleList: React.Dispatch<React.SetStateAction<string>>;
  widgetType?: UserType;
}) => {
  const { isAdmin } = useUserIsAdmin();
  const { logoutContact } = useContactLogout();
  const dispatch = useAppDispatch();
  const { user } = useAppSelector((state) => state.auth);
  const { selectedSettings } = useAppSelector((state) => state.settings);
  const { selectedChat, mineChats } = useAppSelector((state) => state.chats);
  const { isSending } = useAppSelector((state) => state.messages);
  const [openPicker, setOpenPicker] = useState(false);
  const [sending, setSending] = useState(isSending);
  const [openAttachsPicker, setOpenAttachsPicker] = useState(false);
  const inputRef = useRef<null | HTMLDivElement>(
    null
  ) as React.MutableRefObject<HTMLInputElement>;
  const fileRef = useRef<File>(null);

  const clearSendBox = () => {
    setMessageSent(true);
    if (keyword.length > 0) setKeyword("");
    setIsReplying(false);
    setMessageReplied(null);
    setOpenPicker(false);
    setOpenAttachsPicker(false);
    setSending(false);
    window.setTimeout(() => {
      document.getElementById("message-textarea")?.focus();
    }, 0);
  };

  useEffect(() => {
    clearSendBox();
  }, [selectedChat]);

  useEffect(() => {
    setSending(isSending);
  }, [isSending]);
  const URL = process.env.REACT_APP_API_BASE_URL;

  const uploadFilesAction = async () => {
    if (!fileRef.current) return "error";
    if (selectedChat?.type === ChatType.WHATSAPP) {
      return authService.uploadFileWhatsApp({
        _files: [fileRef.current],
        audio: true,
        externalapp:
          selectedChat?.externalapp?._id ||
          selectedChat?.externalapp ||
          undefined,
      });
    }
    return authService.uploadFile(
      [fileRef.current],
      undefined,
      true,
      selectedChat?.type === ChatType.BOTMAKER
    );
  };

  const handleFileErrors = (files: string) => {
    setOpenPicker(false);
    setOpenAttachsPicker(false);
    setSending(false);
    window.setTimeout(() => {
      document.getElementById("message-textarea")?.focus();
    }, 0);
    const _messageError = files.toLowerCase().includes("unsupported")
      ? "Formato de arquivo não suportado!"
      : files.toLowerCase().includes("too large")
      ? "Tamanho máximo por arquivo: 20MB"
      : "Algo deu errado no upload dos arquivos!";
    customToast({
      type: IToastType.ERROR,
      message: _messageError,
    });
  };

  const createMessagesAction = async (fileIds: string[], files: Files) => {
    if (!selectedChat) return;
    // AGITALKS DB MESSAGE
    const newMessage: INewMessage = {
      chat: selectedChat,
      content: message || "",
      files: fileIds,
      parent: isReplying ? messageReplied?._id || "" : null,
      referenceid: "",
      origin: user?._id,
      sendertype: user?.roles?.includes(RoleUser.CONTACT)
        ? SenderType.CONTACT
        : SenderType.USER,
      inactivetime:
        !user?.roles?.includes(RoleUser.AGENT) && !isAdmin
          ? selectedSettings?.inactivetime || "23:59"
          : undefined,
    };
    const { payload } = await dispatch(createMessage(newMessage));

    // WHATSAPP MESSAGE
    if (payload && selectedChat?.type === ChatType.WHATSAPP) {
      const _newMsg: IMessage = payload;
      const newMessageWpp: any = {
        message_id: _newMsg._id,
        externalapp:
          _newMsg?.chat?.externalapp?._id ||
          _newMsg?.chat?.externalapp ||
          undefined,
        messaging_product: "whatsapp",
        to:
          `${
            selectedChat?.users?.find((_user) => _user._id !== user?._id)?.phone
          }` || "",
        recipient_type: "individual",
        context:
          typeof _newMsg.parent?.referenceid !== "undefined"
            ? {
                message_id: _newMsg.parent?.referenceid || "",
              }
            : undefined,
        type: "audio",
        audio: {
          id: files ? files[0].referenceid : "",
        },
      };
      if (
        typeof _newMsg.parent?.referenceid !== "undefined" &&
        _newMsg.parent?.referenceid !== ""
      ) {
        newMessageWpp.context = {
          message_id: _newMsg.parent?.referenceid || "",
        };
      }
      await dispatch(sendMessageWpp(newMessageWpp));
    } else if (payload && selectedChat?.type === ChatType.BOTMAKER) {
      // AGIBOT MESSAGE
      const _newMsg: IMessage = payload;

      const newMessageBotmaker: INewMessageBotmaker = {
        message_id: _newMsg._id,
        messaging_product: "botmaker",
        to: `${payload?.sender?._id}` || "",
        type: "text",
        recipient_type: "individual",
        // files: botmaker_thumbs,
        message: {
          text: _newMsg.content,
        },
        externalapp: payload.chat.externalapp,
        externalcode: payload.chat.externalcode,
        externalcodephone: payload.chat.externalcodephone,
      };

      if (files && files !== null && files?.length > 0) {
        let i = 0;
        for await (const _file of files) {
          const messageContent = {
            ...newMessageBotmaker.message,
            media: {
              mimeType: _file.mimetype,
              url: `${URL}/api/files/${_file.path}/${_file.name}`,
              id: `${_file._id}`,
              path: `${_file.path}/${_file.name}`,
              userId: `${payload?.sender?._id}`,
            },
          };
          newMessageBotmaker.message = messageContent;
          await dispatch(sendMessageBotmaker(newMessageBotmaker));
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          i++;
        }
      } else {
        await dispatch(sendMessageBotmaker(newMessageBotmaker));
      }
    }
  };

  const sendingAudioMessage = async () => {
    if (!selectedChat || !fileRef.current) return;
    setSending(true);
    let files: Files | string | null = [];
    let fileIds: string[] = [];

    files = await uploadFilesAction();

    if (typeof files === "string") {
      handleFileErrors(files);
      return;
    }

    if (files && files !== null && files?.length > 0) {
      fileIds = files.map((_file) => _file?._id || "");

      await createMessagesAction(fileIds, files);

      clearSendBox();
      files = [];
      fileIds = [];
    }
  };

  const blobToFile = (theBlob: Blob, fileName: string): File => {
    return new File([theBlob as Blob], fileName, {
      lastModified: new Date().getTime(),
      type: theBlob.type,
    });
  };

  const onStopAction = async (blobUrl: string, blob: Blob) => {
    if (!selectedChat) return;
    fileRef.current = blobToFile(blob, "audio.mp3");
    await sendingAudioMessage();
  };

  useEffect(
    () => () => {
      window.URL = window.URL || window.webkitURL;
      thumbs.forEach((file: IAcceptedFile) =>
        window.URL.revokeObjectURL(file.preview || "")
      );
    },
    [thumbs]
  );

  const [showModal, setShowModal] = useState<boolean>(false);

  const handleCommenttemplateClick = () => {
    if (!showModal) setShowModal(true);
  };

  const onEmojiClick = (
    e: React.MouseEvent<Element, MouseEvent>,
    emojiObject: IEmojiData
  ) => {
    e.preventDefault();
    if (message !== undefined) setMessage(message.concat(emojiObject.emoji));
  };

  const sendInternalMessage = async (_chat: any) => {
    if (_chat) {
      const newMessage: INewMessage = {
        chat: _chat,
        content: `Agente <b>${user?.name}</b> iniciou a conversa.`,
        files: [],
        parent: null,
        internal: true,
        read: true,
      };
      const { payload } = await dispatch(createMessage(newMessage));

      if (payload !== null && payload?.chat?.type === ChatType.BOTMAKER) {
        const newMessageBotmaker: INewMessageBotmaker = {
          externalapp: payload?.chat?.externalapp,
          externalcode: payload?.chat?.externalcode,
          externalcodephone: payload?.chat?.externalcodephone,
          message_id: payload?._id,
          messaging_product: "botmaker",
          to:
            `${
              selectedChat?.users?.find(
                (_user: IUser) => _user._id !== user?._id
              )?.phone
            }` || "",
          type: "text",
          recipient_type: "individual",
          message: {
            preview_url: true,
            text: `Agente *${user?.name}* iniciou a conversa.`,
          },
        };
        await dispatch(sendMessageBotmaker(newMessageBotmaker));
      } else if (
        payload !== null &&
        payload?.chat?.type === ChatType.WHATSAPP
      ) {
        const newMessageWpp: INewMessageWpp = {
          message_id: payload?._id,
          messaging_product: "whatsapp",
          externalapp:
            payload?.chat?.externalapp?._id ||
            payload?.chat?.externalapp ||
            undefined,
          to:
            `${
              selectedChat?.users?.find(
                (_user: IUser) => _user._id !== user?._id
              )?.phone
            }` || "",
          type: "text",
          recipient_type: "individual",
          text: {
            preview_url: true,
            body: `Agente *${user?.name}* iniciou a conversa.`,
          },
        };
        await dispatch(sendMessageWpp(newMessageWpp));
      }
    }
  };

  const handleStart = async () => {
    try {
      // if(blocked.length == 0 || blocked != selectedChat?._id  ){
      // dispatch(
      //   blockChat({
      //     chat: selectedChat as IChat,
      //     block:true
      //   })
      // );
      let addMyUserInChat =
        selectedChat?.users && user?._id ? [...selectedChat.users, user] : [];

      addMyUserInChat = removeDuplicates(addMyUserInChat, "_id");
      const _usersOnlyID = addMyUserInChat.map((_user: IUser) => _user._id);
      const _chat = {
        ...selectedChat,
        _id: selectedChat?._id,
        status: ChatStatus.ACTIVE,
        users: _usersOnlyID,
        tags: selectedChat?.tags?.map((_tag) => _tag._id),
        start: true,
      };

      const { payload } = await dispatch(updateChat(_chat));

      if (typeof payload._id !== "undefined") {
        if (selectedChat?.status === ChatStatus.WAIT) {
          await sendInternalMessage(_chat);
        }
        dispatch(
          reselectChat({
            prevStatus: ChatStatus.WAIT,
            chat: {
              ...selectedChat,
              status: ChatStatus.ACTIVE,
              started_at: payload.started_at,
              started_by: payload.started_by,
              users: addMyUserInChat,
              updated_at: payload.updated_at,
              updated_by: payload.updated_by,
            },
          })
        );
      } else {
        dispatch(closeChat());
        customToast({
          type: IToastType.WARNING,
          message: `Outro agente já iniciou esta conversa.`,
        });
      }
      dispatch(
        removeFromBacklog({
          prevStatus: ChatStatus.WAIT,
          sessionId: user?._id,
          chat: {
            ...selectedChat,
            status: ChatStatus.ACTIVE,
            started_at: payload.started_at,
            started_by: payload.started_by,
            users: addMyUserInChat,
            updated_at: payload.updated_at,
            updated_by: payload.updated_by,
          },
        })
      );
      if (selectedChat) {
        dispatch(
          triggerChatInactive({
            chat: {
              ...selectedChat,
              status: ChatStatus.ACTIVE,
              started_at: payload.started_at,
              started_by: payload.started_by,
              users: addMyUserInChat,
              updated_at: payload.updated_at,
              updated_by: payload.updated_by,
            },
            inactivetime: selectedSettings?.inactivetime || "23:59",
          })
        );

        if (selectedChat?.team) {
          let _team = "";
          if (typeof selectedChat.team === "string") {
            _team = selectedChat.team;
          } else if (typeof selectedChat.team?._id !== "undefined") {
            _team = selectedChat.team._id;
          }
          if (_team.length > 0 && user?._id) {
            await teamsService.chatUsers({
              _team,
              user: user._id,
              action: "add",
            });
          }
        }
      }
      // dispatch(
      //   chatIsBlocked("")
      // );
      setToggleList("mine");
      // }
    } catch (error) {
      // console.log(error);
    }
  };

  const handleSendClick = async (
    commenttemplate?: string,
    _content?: string,
    _filesId?: string[],
    _filesExternalapps?: any
  ) => {
    const pathToAcceptedFile = async (
      src: string,
      fileName: string,
      mimeType: string
    ) => {
      const response = await axios.get(src, { responseType: "blob" });
      const blob = new Blob([response.data], { type: mimeType });
      const file = new File([blob], fileName, { type: mimeType });
      Object.assign(file, {
        preview: window.URL.createObjectURL(file),
      });
      return file;
    };

    const getBlobFiles = async () => {
      const _blobfiles = [];
      if (
        typeof _filesExternalapps !== "undefined" &&
        _filesExternalapps.length > 0
      ) {
        for await (const _file of _filesExternalapps) {
          const _blobFile = await pathToAcceptedFile(
            `${URL}/api/files/${_file.path}/${_file.name}`,
            _file.name,
            _file.mimetype
          );
          _blobfiles.push(_blobFile);
        }
      }
      return _blobfiles;
    };

    if (!selectedChat) return;
    setSending(true);
    let files: Files | string | null = [];
    let fileIds: string[] = [];
    let botmakerThumbs: IAcceptedFiles = [];
    if (
      (_content && _content.length > 0) ||
      (message && message.length > 0) ||
      thumbs.length > 0
    ) {
      if (
        (_filesExternalapps && _filesExternalapps.length > 0) ||
        thumbs.length > 0
      ) {
        if (selectedChat?.type === ChatType.WHATSAPP) {
          const _thumbs = await getBlobFiles();
          files = await authService.uploadFileWhatsApp({
            _files:
              _filesExternalapps && _filesExternalapps.length > 0
                ? _thumbs
                : thumbs,
            externalapp:
              selectedChat?.externalapp?._id ||
              selectedChat?.externalapp ||
              undefined,
          });
        } else {
          files =
            _filesExternalapps && _filesExternalapps.length > 0
              ? _filesExternalapps
              : await authService.uploadFile(thumbs);
          botmakerThumbs =
            _filesExternalapps && _filesExternalapps.length > 0
              ? _filesExternalapps
              : thumbs;
        }
      }
      if (typeof files === "string") {
        setOpenPicker(false);
        setOpenAttachsPicker(false);
        setSending(false);
        window.setTimeout(() => {
          document.getElementById("message-textarea")?.focus();
        }, 0);
        const _messageError = files.toLowerCase().includes("unsupported")
          ? "Formato de arquivo não suportado!"
          : files.toLowerCase().includes("too large")
          ? "Tamanho máximo por arquivo: 20MB"
          : "Algo deu errado no upload dos arquivos!";
        customToast({
          type: IToastType.ERROR,
          message: _messageError,
        });
        return;
      }
      if (files && files !== null && files?.length > 0) {
        fileIds = files.map((file) => file?._id || "");
      }

      const newMessage: INewMessage = {
        chat: selectedChat,
        sendertype: user?.roles?.includes(RoleUser.CONTACT)
          ? SenderType.CONTACT
          : SenderType.USER,
        content: _content || message || "",
        commenttemplate,
        files: (_filesId as string[]) || fileIds || [],
        parent: isReplying ? messageReplied?._id || "" : null,
        referenceid: "",
        origin: user?._id,
        inactivetime:
          !user?.roles?.includes(RoleUser.AGENT) && !isAdmin
            ? selectedSettings?.inactivetime || "23:59"
            : undefined,
      };
      setMessage("");
      setThumbs([]);
      const { payload } = await dispatch(createMessage(newMessage));

      if (payload && selectedChat?.type === ChatType.BOTMAKER) {
        const _newMsg: IMessage = payload;
        const newMessageBotmaker: INewMessageBotmaker = {
          message_id: _newMsg._id,
          messaging_product: "botmaker",
          to: `${payload?.sender?._id}` || "",
          type: "text",
          recipient_type: "individual",
          files: botmakerThumbs,
          message: {
            text:
              typeof commenttemplate !== "undefined" &&
              commenttemplate.length > 0
                ? stripTags({
                    value: useFormatText(_newMsg.content as string, true),
                  })
                : _newMsg.content,
          },
          externalapp: payload.chat.externalapp,
          externalcode: payload.chat.externalcode,
          externalcodephone: payload.chat.externalcodephone,
        };

        if (
          typeof _newMsg.parent?.referenceid !== "undefined" &&
          _newMsg.parent?.referenceid !== ""
        ) {
          newMessageBotmaker.context = {
            message_id: _newMsg.parent?.referenceid || "",
          };
        }
        if (files && files !== null && files?.length > 0) {
          fileIds = files.map((file) => file?._id || "");

          let i = 0;
          for await (const _file of files) {
            const messageContent = {
              ...newMessageBotmaker.message,
              media: {
                mimeType: _file.mimetype,
                url: `${URL}/api/files/${_file.path}/${_file.name}`,
                id: `${_file._id}`,
                path: `${_file.path}/${_file.name}`,
                userId: `${payload?.sender?._id}`,
              },
            };

            newMessageBotmaker.message = messageContent;
            await dispatch(sendMessageBotmaker(newMessageBotmaker));
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            i++;
          }
        } else {
          await dispatch(sendMessageBotmaker(newMessageBotmaker));
        }
      }

      if (payload && selectedChat?.type === ChatType.WHATSAPP) {
        const _newMsg: IMessage = payload;
        if (
          files &&
          typeof files !== "string" &&
          files !== null &&
          files.length > 0
        ) {
          for await (const _file of files) {
            const newMessageWpp = {
              video: {
                externalapp:
                  _newMsg?.chat?.externalapp?._id ||
                  _newMsg?.chat?.externalapp ||
                  undefined,
                message_id: _newMsg._id,
                messaging_product: "whatsapp",
                to:
                  `${
                    selectedChat?.users?.find(
                      (_user) => _user._id !== user?._id
                    )?.phone
                  }` || "",
                type: "video",
                recipient_type: "individual",
                context:
                  typeof _newMsg.parent?.referenceid !== "undefined"
                    ? {
                        message_id: _newMsg.parent?.referenceid || "",
                      }
                    : undefined,
                video: {
                  caption: _newMsg?.content
                    ? typeof commenttemplate !== "undefined" &&
                      commenttemplate.length > 0
                      ? stripTags({
                          value: useFormatText(_newMsg.content as string, true),
                        })
                      : _newMsg.content
                    : undefined,
                  id: _file.referenceid,
                },
              },
              image: {
                externalapp:
                  _newMsg?.chat?.externalapp?._id ||
                  _newMsg?.chat?.externalapp ||
                  undefined,
                message_id: _newMsg._id,
                messaging_product: "whatsapp",
                to:
                  `${
                    selectedChat?.users?.find(
                      (_user) => _user._id !== user?._id
                    )?.phone
                  }` || "",
                type: "image",
                recipient_type: "individual",
                context:
                  typeof _newMsg.parent?.referenceid !== "undefined"
                    ? {
                        message_id: _newMsg.parent?.referenceid || "",
                      }
                    : undefined,
                image: {
                  caption: _newMsg?.content
                    ? typeof commenttemplate !== "undefined" &&
                      commenttemplate.length > 0
                      ? stripTags({
                          value: useFormatText(_newMsg.content as string, true),
                        })
                      : _newMsg.content
                    : undefined,
                  id: _file.referenceid,
                },
              },
              sticker: {
                externalapp:
                  _newMsg?.chat?.externalapp?._id ||
                  _newMsg?.chat?.externalapp ||
                  undefined,
                message_id: _newMsg._id,
                messaging_product: "whatsapp",
                to:
                  `${
                    selectedChat?.users?.find(
                      (_user) => _user._id !== user?._id
                    )?.phone
                  }` || "",
                type: "sticker",
                recipient_type: "individual",
                context:
                  typeof _newMsg.parent?.referenceid !== "undefined"
                    ? {
                        message_id: _newMsg.parent?.referenceid || "",
                      }
                    : undefined,
                sticker: {
                  caption: _newMsg?.content
                    ? typeof commenttemplate !== "undefined" &&
                      commenttemplate.length > 0
                      ? stripTags({
                          value: useFormatText(_newMsg.content as string, true),
                        })
                      : _newMsg.content
                    : undefined,
                  id: _file.referenceid,
                },
              },
              document: {
                externalapp:
                  _newMsg?.chat?.externalapp?._id ||
                  _newMsg?.chat?.externalapp ||
                  undefined,
                message_id: _newMsg._id,
                messaging_product: "whatsapp",
                to:
                  `${
                    selectedChat?.users?.find(
                      (_user) => _user._id !== user?._id
                    )?.phone
                  }` || "",
                type: "document",
                recipient_type: "individual",
                context:
                  typeof _newMsg.parent?.referenceid !== "undefined"
                    ? {
                        message_id: _newMsg.parent?.referenceid || "",
                      }
                    : undefined,
                document: {
                  caption: _newMsg?.content
                    ? typeof commenttemplate !== "undefined" &&
                      commenttemplate.length > 0
                      ? stripTags({
                          value: useFormatText(_newMsg.content as string, true),
                        })
                      : _newMsg.content
                    : undefined,
                  filename: _file.name,
                  id: _file.referenceid,
                },
              },
              audio: {
                externalapp:
                  _newMsg?.chat?.externalapp?._id ||
                  _newMsg?.chat?.externalapp ||
                  undefined,
                message_id: _newMsg._id,
                messaging_product: "whatsapp",
                to:
                  `${
                    selectedChat?.users?.find(
                      (_user) => _user._id !== user?._id
                    )?.phone
                  }` || "",
                type: "audio",
                recipient_type: "individual",
                context:
                  typeof _newMsg.parent?.referenceid !== "undefined"
                    ? {
                        message_id: _newMsg.parent?.referenceid || "",
                      }
                    : undefined,
                audio: {
                  // link:
                  //   files[0] && files[0].name && files[0].path
                  //     ? `${URL}/api/files/${files[0].path}/${files[0].name}`
                  //     : "",
                  id: files ? files[0].referenceid : "",
                },
              },
            };
            if (_file.mimetype?.includes("image")) {
              await dispatch(sendMessageWpp(newMessageWpp.image));
            } else if (_file.mimetype?.includes("video")) {
              await dispatch(sendMessageWpp(newMessageWpp.video));
              // } else if (_file.mimetype?.includes("audio")) {
              //   await dispatch(sendMessageWpp(newMessageWpp.audio));
            } else if (_file.mimetype?.includes("image/webp")) {
              await dispatch(sendMessageWpp(newMessageWpp.sticker));
            } else {
              await dispatch(sendMessageWpp(newMessageWpp.document));
            }
          }
        } else {
          const newMessageWpp: INewMessageWpp = {
            message_id: _newMsg._id,
            messaging_product: "whatsapp",
            externalapp:
              _newMsg?.chat?.externalapp?._id ||
              _newMsg?.chat?.externalapp ||
              undefined,
            to:
              `${
                selectedChat?.users?.find((_user) => _user._id !== user?._id)
                  ?.phone
              }` || "",
            type: "text",
            recipient_type: "individual",
            text: {
              preview_url: true,
              body: `*[${_newMsg.sender.name}]*\n${
                typeof commenttemplate !== "undefined" &&
                commenttemplate.length > 0
                  ? stripTags({
                      value: useFormatText(_newMsg.content as string, true),
                    })
                  : _newMsg.content
              }`,
            },
          };
          if (
            typeof _newMsg.parent?.referenceid !== "undefined" &&
            _newMsg.parent?.referenceid !== ""
          ) {
            newMessageWpp.context = {
              message_id: _newMsg.parent?.referenceid || "",
            };
          }
          await dispatch(sendMessageWpp(newMessageWpp));
        }
      }
      setMessageSent(true);
      if (keyword.length > 0) setKeyword("");
      setMessage("");
      setThumbs([]);
      files = [];
      fileIds = [];
      setIsReplying(false);
      setMessageReplied(null);
      setOpenPicker(false);
      setOpenAttachsPicker(false);
      setSending(false);
      window.setTimeout(() => {
        document.getElementById("message-textarea")?.focus();
      }, 0);
    }
  };

  const handleMessageChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    e.preventDefault();
    setMessage(e.target.value);
  };

  const handleClose = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setOpenPicker(false);
    setOpenAttachsPicker(false);
  };

  const handleEmojiClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    // setOpenAttachsPicker(false);
    setOpenPicker((prevState) => !prevState);
  };
  const handleClick = () => {
    const clickRef = inputRef.current;
    clickRef?.click();
  };

  const handleFocus = (focus: boolean) => {
    setIsFocused(focus);
  };

  const accept =
    "application/pdf, text/html, text/plain, text/csv, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/gzip, application/vnd.oasis.opendocument.presentation, application/vnd.oasis.opendocument.spreadsheet, application/vnd.oasis.opendocument.text, application/vnd.ms-powerpoint, application/vnd.openxmlformats-officedocument.presentationml.presentation, application/vnd.rar, application/rtf, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/x-zip-compressed, application/x-7z-compressed, application/x-rar-compressed, image/*, video/*, audio/*";

  const acceptWhatsApp =
    // "image/jpeg, image/png, audio/aac, audio/mp4, audio/mpeg, audio/amr, audio/ogg, audio/oga, video/mp4, video/3gp, image/webp, text/plain, application/pdf, application/vnd.ms-powerpoint, application/msword, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.openxmlformats-officedocument.presentationml.presentation, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
    "image/jpeg, image/png, audio/aac, audio/mpeg, audio/ogg, video/mp4, video/3gpp, image/webp, text/plain, application/pdf, application/vnd.ms-powerpoint, application/msword, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.openxmlformats-officedocument.presentationml.presentation, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";

  const acceptArray = [
    "application/pdf",
    "text/html",
    "text/plain",
    "text/csv",
    "application/msword",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    "application/gzip",
    "application/vnd.oasis.opendocument.presentation",
    "application/vnd.oasis.opendocument.spreadsheet",
    "application/vnd.oasis.opendocument.text",
    "application/vnd.ms-powerpoint",
    "application/vnd.openxmlformats-officedocument.presentationml.presentation",
    "application/vnd.rar",
    "application/rtf",
    "application/vnd.ms-excel",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    "application/x-zip-compressed",
    "application/x-7z-compressed",
    "application/x-rar-compressed",
    "image/*",
    "video/*",
    "audio/*",
  ];

  const acceptWhatsAppArray = [
    "image/jpeg",
    "image/png",
    " audio/aac",
    // "audio/mp4",
    "audio/mpeg",
    // "audio/amr",
    "audio/ogg",
    // "audio/oga",
    "video/mp4",
    "video/3gpp",
    "image/webp",
    "text/plain",
    "application/pdf",
    "application/vnd.ms-powerpoint",
    "application/msword",
    "application/vnd.ms-excel",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    "application/vnd.openxmlformats-officedocument.presentationml.presentation",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  ];

  const handleFilePaste = (list: any) => {
    const acceptedFiles: IAcceptedFiles = [];

    Array.from(list || []).forEach((file: any) => {
      if (
        (acceptArray.includes(file.type) ||
          file.type.includes("image/") ||
          file.type.includes("video/") ||
          file.type.includes("audio/")) &&
        ((selectedChat?.type === ChatType.WHATSAPP &&
          acceptWhatsAppArray.includes(file.type)) ||
          selectedChat?.type !== ChatType.WHATSAPP)
      ) {
        if (file.size > 20000000) {
          customToast({
            type: IToastType.WARNING,
            message: t("toast.error.files.file-too-large"),
          });
          // return false;
        } else {
          acceptedFiles.push(file);
        }
        // return true;
      } else {
        customToast({
          type: IToastType.WARNING,
          message: t("toast.error.files.unsupported"),
        });
        // return false;
      }
    });

    window.URL = window.URL || window.webkitURL;
    Array.from(acceptedFiles || []).forEach((file) => {
      return setThumbs((oldArray: IAcceptedFiles) => [
        Object.assign(file, {
          preview: window.URL.createObjectURL(file),
        }),
        ...oldArray,
      ]);
    });
  };

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const acceptedFiles: IAcceptedFiles = [];
    Array.from(event.target.files || []).forEach((file) => {
      if (
        (acceptArray.includes(file.type) ||
          file.type.includes("image/") ||
          file.type.includes("video/") ||
          file.type.includes("audio/")) &&
        ((selectedChat?.type === ChatType.WHATSAPP &&
          acceptWhatsAppArray.includes(file.type)) ||
          selectedChat?.type !== ChatType.WHATSAPP)
      ) {
        if (file.size > 20000000) {
          customToast({
            type: IToastType.WARNING,
            message: t("toast.error.files.file-too-large"),
          });
          // return false;
        } else {
          acceptedFiles.push(file);
        }
        // return true;
      } else {
        customToast({
          type: IToastType.WARNING,
          message: t("toast.error.files.unsupported"),
        });
        // return false;
      }
    });
    window.URL = window.URL || window.webkitURL;
    Array.from(acceptedFiles || []).forEach((file) => {
      return setThumbs((oldArray: IAcceptedFiles) => [
        Object.assign(file, {
          preview: window.URL.createObjectURL(file),
        }),
        ...oldArray,
      ]);
    });
  };

  const getFooterBarType = () => {
    if (
      typeof selectedChat?.status !== "undefined" &&
      [ChatStatus.FINISH, ChatStatus.CANCELED].includes(selectedChat.status) &&
      widgetType === UserType.CONTACT
    )
      return (
        <Button
          minWidth
          red
          extraClass="absolute bottom-0 right-0 m-[25px] xl:w-1/4 w-1/3"
          label="Sair"
          onClick={async () => {
            await logoutContact({});
          }}
          icon="las la-times"
        />
      );
    if (
      selectedChat?.type !== ChatType.INTERNAL &&
      selectedChat?.status === ChatStatus.WAIT &&
      selectedChat?.users?.length === 1 &&
      widgetType !== UserType.CONTACT
      // && !selectedChat.isblocked
    )
      return (
        <FormButton
          className="absolute bottom-0 right-0 m-[25px] xl:w-1/4 w-1/3 min-w-fit"
          disabled={
            selectedChat?.team?.chatlimitsize
              ? selectedChat?.team?.chatlimitsize <=
                mineChats.filter(
                  (_chat) =>
                    _chat.type !== ChatType.INTERNAL &&
                    _chat.status === ChatStatus.ACTIVE
                ).length
              : false
          }
          label="Iniciar conversa"
          onClick={handleStart}
          icon="las la-play mr-1"
        />
      );
    if (
      (typeof selectedChat?.status !== "undefined" &&
        [
          ChatStatus.FINISH,
          ChatStatus.WAITREPLY,
          ChatStatus.WAITCAMPAIGN,
          ChatStatus.CANCELED,
        ].includes(selectedChat.status)) ||
      selectedChat?.users?.find((_user) => _user._id === user?._id) ===
        undefined
    )
      return null;
    return (
      <div className="flex flex-col">
        {openPicker ? (
          <div className="h-[320px] overflow-y-hidden transition-all">
            <EmojiPicker
              groupVisibility={{
                flags: false,
              }}
              onEmojiClick={onEmojiClick}
            />
          </div>
        ) : null}
        <SecondaryOptions
          handleClick={handleClick}
          handleEmojiClick={handleEmojiClick}
          handleCommenttemplateClick={handleCommenttemplateClick}
          openAttachsPicker={openAttachsPicker}
          openPicker={openPicker}
        />
        <PrimaryOptions
          accept={
            selectedChat?.type === ChatType.WHATSAPP ? acceptWhatsApp : accept
          }
          // handleClick={handleClick}
          handleFocus={handleFocus}
          handleFileChange={handleFileChange}
          handleFilePaste={handleFilePaste}
          inputRef={inputRef}
          keyword={keyword}
          message={message}
          sending={sending}
          setSending={setSending}
          setKeyword={setKeyword}
          thumbs={thumbs}
          handleSendClick={handleSendClick}
          handleMessageChange={handleMessageChange}
          handleClose={handleClose}
          openAttachsPicker={openAttachsPicker}
          // openPicker={openPicker}
          setOpenAttachsPicker={setOpenAttachsPicker}
          sendAudio={onStopAction}
        />
        {showModal ? (
          <RelationModal
            widgetType={widgetType}
            title="Selecione o modelo de resposta"
            icon="las la-comment"
            setShowModal={setShowModal}
            setShow={setShowModal}
            sendCommenttemplate={handleSendClick}
          />
        ) : null}
      </div>
    );
  };

  return getFooterBarType();
};

SendBox.defaultProps = {
  message: "",
  widgetType: UserType.NONE,
};

export default SendBox;
