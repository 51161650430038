import React from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import MainHeader from "../../../../shared/components/MainHeader.component";
import UserProfileStatus from "../../../../shared/components/UserProfileStatus.component";
import { useAppDispatch, useAppSelector } from "../../../../hooks/redux/hooks";
import { userdata } from "../../../../slices/auth.slice";
import ToggleInbox from "./ToggleInbox.component";
import ToggleUsers from "./ToggleUsers.component";
import { UserType } from "../../../../slices/users.slice";
import customToast from "../../../../shared/utils/customToast";
import { IToastType } from "../../../../shared/models/types/Toast.type";
import { externalappsService, usersService } from "../../../../services";
import {
  ExternalAppType,
  IExternalAppBotmaker,
  IExternalAppWhatsApp,
  selectExternalappsBotmaker,
  selectExternalappsWhatsApp,
  selectTotalExternalappsBotmaker,
  selectTotalExternalappsWhatsApp,
} from "../../../../slices/externalapps.slice";
import Button from "../../../../shared/components/Buttons/Button.component";
import useUserIsAdmin from "../../../../hooks/useUserIsAdmin";
import { selectTemplate } from "../../../../slices/messages.slice";

const ToggleSelectHeader = ({
  type,
  setShow,
  setShowWidgetAlert,
  setShowNoTeamsAlert,
  setShowNewGroupChat,
  toggleList,
  setToggleList,
  notificationsBacklog,
  notificationsMe,
  notificationsTeams,
  widgetType,
  showHistory,
  setShowHistory,
  showFilter,
  setShowFilter,
}: {
  type: string;
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
  setShowWidgetAlert?: React.Dispatch<React.SetStateAction<boolean>>;
  setShowNoTeamsAlert?: React.Dispatch<React.SetStateAction<boolean>>;
  setShowNewGroupChat: React.Dispatch<React.SetStateAction<boolean>>;
  toggleList: string;
  setToggleList: React.Dispatch<React.SetStateAction<string>>;
  notificationsBacklog?: number;
  notificationsMe?: number;
  notificationsTeams?: number;
  widgetType: UserType | undefined;
  showHistory: boolean;
  setShowHistory: React.Dispatch<React.SetStateAction<boolean>>;
  showFilter: boolean;
  setShowFilter: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const { isAdmin } = useUserIsAdmin();
  const { t } = useTranslation();
  const { user } = useAppSelector((state) => state.auth);
  const { selectedHistoryContact } = useAppSelector((state) => state.chats);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const getTitle = () => {
    if (showHistory) {
      if (
        selectedHistoryContact !== null &&
        typeof selectedHistoryContact?.text !== "undefined" &&
        selectedHistoryContact?.text.length > 0
      ) {
        return `Histórico de ${selectedHistoryContact.text}`;
      }
      return "Histórico";
    }

    if (showFilter) return "Filtros";

    switch (toggleList) {
      case "search":
        return "Resultados";
      case "users":
        return t("toggle-selector.users");
      case "contacts":
        return t("toggle-selector.contacts");
      case "backlog":
        return t("toggle-selector.backlog");
      case "teams":
        return t("toggle-selector.teams");
      case "mine":
        return t("toggle-selector.me");
      default:
        return "";
    }
  };
  const getIcon = () => {
    if (showFilter) return "la-filter";
    if (showHistory) return "la-history";
    switch (toggleList) {
      case "users":
        return "la-user";
      case "contacts":
        return "la-address-book";
      case "backlog":
        return "la-comment-dots";
      case "teams":
        return "la-users";
      case "mine":
        return "la-inbox";
      default:
        return "";
    }
  };

  const getToggleComponent = () => {
    if (showHistory || showFilter) return null;
    if (["inbox", "inbox-widget"].includes(type)) {
      return (
        <ToggleInbox
          toggleList={toggleList}
          setToggleList={setToggleList}
          notificationsBacklog={notificationsBacklog}
          notificationsMe={notificationsMe}
          notificationsTeams={notificationsTeams}
        />
      );
    }
    return (
      <ToggleUsers
        toggleList={toggleList}
        onClickContacts={async () => {
          dispatch(selectTemplate({ template: null }));
          if (user?.teams && user.teams.length > 0) {
            const payload = await externalappsService.searchExternalAppsToChat({
              teams: user.teams?.map((_t) => {
                if (typeof _t === "string") return _t;
                return _t._id || "";
              }),
              types: [ExternalAppType.WHATSAPP, ExternalAppType.BOTMAKER],
            });

            if (
              !payload ||
              payload.length === 0 ||
              payload.statusCode === 401
            ) {
              if (widgetType === UserType.NONE && isAdmin) {
                navigate("/settings/integrations");
                customToast({
                  type: IToastType.WARNING,
                  message: t("toast.warning.no-integration"),
                });
              } else if (
                (widgetType === UserType.NONE ||
                  widgetType === UserType.AGENT) &&
                setShowWidgetAlert
              ) {
                setShowWidgetAlert(true);
              }
            } else {
              const _whatsapp: IExternalAppWhatsApp[] = [];
              const _bot: IExternalAppBotmaker[] = [];
              payload.forEach(
                (_extApp: IExternalAppWhatsApp | IExternalAppBotmaker) => {
                  if (_extApp.type === ExternalAppType.WHATSAPP) {
                    _whatsapp.push(_extApp as IExternalAppWhatsApp);
                  } else if (_extApp.type === ExternalAppType.BOTMAKER) {
                    _bot.push(_extApp as IExternalAppBotmaker);
                  }
                }
              );
              dispatch(selectExternalappsWhatsApp(_whatsapp));
              dispatch(selectTotalExternalappsWhatsApp(_whatsapp.length));
              dispatch(selectExternalappsBotmaker(_bot));
              dispatch(selectTotalExternalappsBotmaker(_bot.length));
              if (
                payload.length === 1 &&
                typeof payload[0]?._id !== "undefined" &&
                payload[0]._id
              ) {
                const _extapp = payload[0];
                if (
                  _extapp.type === ExternalAppType.BOTMAKER &&
                  _extapp.fields.externalphones.length === 1 &&
                  typeof _extapp.fields.externalphones[0]?.phone !==
                    "undefined" &&
                  _extapp.fields.externalphones[0]?.phone.length > 0
                ) {
                  await usersService.profile({
                    externalapp: _extapp._id,
                    defaulttemplate: "",
                    externalappnumber: _extapp.fields.externalphones[0]?.phone,
                  });
                } else {
                  await usersService.profile({
                    externalapp: _extapp._id,
                    defaulttemplate: "",
                    externalappnumber: user?.profile?.externalappnumber ?? "",
                  });
                }
                await dispatch(userdata());
              }
              setToggleList("contacts");
            }
          } else if (widgetType === UserType.NONE && isAdmin) {
            navigate("/settings/teams");
            customToast({
              type: IToastType.WARNING,
              message: t("toast.warning.no-team"),
            });
          } else if (
            (widgetType === UserType.NONE || widgetType === UserType.AGENT) &&
            setShowNoTeamsAlert
          ) {
            setShowNoTeamsAlert(true);
            customToast({
              type: IToastType.WARNING,
              message: t("toast.warning.no-team"),
            });
          }
        }}
        onClickUsers={() => {
          setToggleList("users");
        }}
      />
    );
  };

  return (
    <div className="flex flex-col">
      <div className="rounded-none h-[24px] m-4 relative flex items-center justify-between">
        <div className="flex -ml-2">
          <UserProfileStatus />
          {getToggleComponent()}
        </div>
        <div className="flex justify-end">
          <MainHeader
            setShow={setShow}
            showHistory={showHistory}
            setShowHistory={setShowHistory}
            showFilter={showFilter}
            setShowFilter={setShowFilter}
            type={type}
          />
        </div>
      </div>

      <div className="pl-[10px] h-[36px] pt-[10px] pr-[5px] flex relative items-center justify-between">
        <div className="relative flex items-center max-w-[90%]">
          <div
            role="presentation"
            className={`las ${getIcon()} text-[22px] hover:text-black hover:cursor-auto`}
            // aria-label="title"
          />
          <h2 className="ml-[5px] text-[18px] word-wrap-1">{getTitle()}</h2>
        </div>

        {toggleList === "users" ? (
          <Button
            minWidth={false}
            disabled={false}
            height="h-[28px]"
            width="w-[118px]"
            extraClass={widgetType === UserType.AGENT ? "mr-[46px]" : "mr-4"}
            label="Novo grupo"
            icon="las la-plus"
            onClick={() => {
              setShowNewGroupChat((prevState) => !prevState);
            }}
          />
        ) : null}
      </div>
    </div>
  );
};

ToggleSelectHeader.defaultProps = {
  notificationsBacklog: 0,
  notificationsMe: 0,
  notificationsTeams: 0,
  setShowWidgetAlert: undefined,
  setShowNoTeamsAlert: undefined,
};

export default ToggleSelectHeader;
