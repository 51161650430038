import React, { FormEvent, useEffect, useState } from "react";
import { Form } from "semantic-ui-react";
import { useNavigate } from "react-router-dom";

import { useTranslation } from "react-i18next";
import useInput from "../../../../hooks/useInput";
import { useAppDispatch, useAppSelector } from "../../../../hooks/redux/hooks";

import { validateNameLength } from "../../../../shared/utils/validation/length";
import validateEmail from "../../../../shared/utils/validation/email";

import {
  authenticationWidgetContact,
  changeStatus,
  reselectUser,
  setupsocket,
} from "../../../../slices/auth.slice";
import {
  ChatStatus,
  ChatType,
  createChat,
  IChat,
  saveWaitingRoomTempAgent,
} from "../../../../slices/chats.slice";
import {
  createMessage,
  INewMessage,
  SenderType,
} from "../../../../slices/messages.slice";
import {
  createContact,
  IUser,
  RoleUser,
  UserStatus,
  UserType,
} from "../../../../slices/users.slice";

import TermsAndPolices from "../../../../shared/components/TermsAndPolices.component";
import LoadingDots from "../../../../shared/components/LoadingDots.component";
import { getSettings, ISettings } from "../../../../slices/settings.slice";
import {
  // ITeam,
  teamsAssignment,
  UserSettings,
} from "../../../../slices/teams.slice";
// import PhoneMaskInput from "../../../../shared/components/PhoneMaskInput.component";
import FormButton from "../../../../shared/components/Buttons/FormButton.component";
import removeDuplicates from "../../../../shared/utils/removeDuplicates";
import ErrorLabel from "./ErrorLabel.component";
import { ddOptions } from "../../../../shared/models/interfaces/dropdownOptions.interface";
import Dropdown from "../../../../shared/components/Dropdown.component";
import { messagesService, offlinemessagesService } from "../../../../services";
import OfficehoursLink from "../../../../widget/OfficehoursLink.component";
import findInObject from "../../../../shared/utils/findInObject";
import {
  IOfflinemessageType,
  IOfflineType,
} from "../../../../slices/offlinemessages.slice";
import PhoneFlagInput from "../../../../shared/components/PhoneFlagInput.component";
import { validNumberLength } from "../../../../shared/utils/handleMaskLength";

const LoginContactForm = ({
  widgetType,
  isOffline,
  setIsOffline,
}: {
  widgetType?: UserType;
  isOffline?: boolean;
  setIsOffline?: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { isLoadingAuth, tenantID } = useAppSelector((state) => state.auth);
  const { selectedSettings } = useAppSelector((state) => state.settings);
  const [teamId, setTeamId] = useState(
    selectedSettings?.defaultteam?.length === 1
      ? selectedSettings?.defaultteam[0]._id
      : ""
  );

  useEffect(() => {
    if (selectedSettings?.defaultteam?.length === 1) {
      if (
        typeof selectedSettings?.defaultteam[0].offline !== undefined &&
        selectedSettings?.defaultteam[0].offline
      ) {
        if (setIsOffline) setIsOffline(true);
      }
    }
  }, []);

  const _message = selectedSettings?.offlinemessage?.message?.find(
    (_offlinemessage: IOfflinemessageType) =>
      _offlinemessage?.type === IOfflineType.WIDGET
  );

  const [isLoadingDropdown, setIsLoadingDropdown] = useState<boolean>(false);
  const [focusId, setFocusId] = useState<string>("");

  const {
    text: name,
    shouldDisplayError: nameHasError,
    textChangeHandler: nameChangeHandler,
    inputBlurHandler: nameBlurHandler,
  } = useInput(validateNameLength);
  const {
    text: help,
    shouldDisplayError: helpHasError,
    textAreaChangeHandler: helpChangeHandler,
    inputBlurHandler: helpBlurHandler,
  } = useInput(validateNameLength);
  const {
    text: email,
    shouldDisplayError: emailHasError,
    emailChangeHandler,
    inputBlurHandler: emailBlurHandler,
  } = useInput(validateEmail);

  const [optionsTeams, setOptionsTeams] = useState<ddOptions[]>([]);
  const [phone, setPhone] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const onSubmitHandler = async (e: FormEvent<HTMLFormElement>) => {
    setIsLoading(true);
    const _sendInternalMessage = async ({
      _chat,
      infoSettings,
      agentid,
    }: {
      _chat: IChat;
      infoSettings: ISettings;
      agentid: string | undefined;
    }) => {
      const newMessage: INewMessage = {
        chat: _chat,
        content: infoSettings?.defaultbot?.welcomemsg || "",
        files: [],
        parent: null,
        internal: true,
        read: true,
      };
      // const { payload } = await dispatch(createMessage(newMessage));
      if (!agentid) {
        await messagesService.create(newMessage);
      }
      // if (typeof payload !== "undefined" && payload !== null) {
      if (agentid && typeof agentid !== "undefined") {
        const agent = _chat.users?.find((_user) => _user._id === agentid);
        const _newMessage: INewMessage = {
          chat: _chat,
          content: `${t("messages.user-start-begin")} <b>${agent?.name}</b> ${t(
            "messages.user-start-end"
          )}`,
          files: [],
          parent: null,
          internal: true,
          read: true,
        };
        // await dispatch(createMessage(_newMessage));
        await messagesService.create(_newMessage);
      }
      if (infoSettings.defaultbot) {
        dispatch(saveWaitingRoomTempAgent(infoSettings.defaultbot));
      }
      navigate(`/chat/${_chat?._id}`);
      setIsLoading(false);
      // }
    };

    const _createMessage = async ({
      uid,
      _chat,
      infoSettings,
      agentid,
    }: {
      uid: string;
      _chat: IChat;
      infoSettings: ISettings;
      agentid: string | undefined;
    }) => {
      const newMessage: INewMessage = {
        chat: _chat,
        content: help || "",
        files: [],
        parent: null,
        origin: uid,
        sendertype: SenderType.CONTACT,
        read: false,
        inactivetime: infoSettings?.inactivetime || "23:59",
      };
      await dispatch(createMessage(newMessage));
      await _sendInternalMessage({ _chat, infoSettings, agentid });
    };

    const _createChat = async ({
      uid,
      agentid,
      teamid,
      infoSettings,
    }: {
      uid: string;
      agentid?: string;
      teamid?: string;
      infoSettings: ISettings;
    }) => {
      let _users: Array<string> = [];
      _users.push(uid);
      if (agentid && agentid.length > 0) {
        _users.push(agentid);
      }
      _users = removeDuplicates(_users);

      const { payload } = await dispatch(
        createChat({
          users: _users,
          type: ChatType.EXTERNAL,
          team: typeof teamid !== "undefined" ? teamid : null,
          status:
            typeof agentid !== "undefined"
              ? ChatStatus.ACTIVE
              : ChatStatus.WAIT,
          externalapp: undefined,
        })
      );
      const _chat = payload as IChat;
      await _createMessage({
        _chat,
        uid,
        infoSettings,
        agentid,
      });
    };

    const _getAssignment = async ({
      uid,
      teamid,
      infoSettings,
    }: {
      uid: string;
      teamid: string;
      infoSettings: ISettings;
    }) => {
      const { payload } = await dispatch(
        teamsAssignment({ teamid /* , contactid: uid */ })
      );
      const _infoTeamAssignment = payload as UserSettings;
      if (!_infoTeamAssignment?.user) {
        await _createChat({ uid, teamid, infoSettings });
      } else {
        const agentid = _infoTeamAssignment.user;
        if (!["unavailable", "unavailableagents"].includes(agentid)) {
          await _createChat({ uid, agentid, teamid, infoSettings });
        } else if (agentid === "unavailable") {
          await _createChat({ uid, teamid, infoSettings });
        } else if (agentid === "unavailableagents") {
          navigate("/unavailableagents");
        }
      }
    };

    const _getDefaultTeam = async (uid: string) => {
      if (!selectedSettings) return false;
      if (!teamId || teamId.length === 0) {
        const infoSettings = selectedSettings;
        await _createChat({ uid, infoSettings });
        return true;
      }
      await _getAssignment({
        uid,
        teamid: teamId,
        infoSettings: selectedSettings,
      });
      return true;
    };

    const _createContact = async () => {
      if (typeof tenantID !== "undefined") {
        const { payload } = await dispatch(
          createContact({
            name: name.trim(),
            email: email.toLowerCase().trim(),
            phone: phone?.replace(/[^0-9.]/g, ""),
            roles: [RoleUser.CONTACT],
            sendinvite: false,
            widget: true,
            tenant: tenantID,
          })
        );
        const _contact = payload as IUser;
        if (typeof _contact?._id !== "undefined") {
          const _response = await dispatch(
            changeStatus({ status: UserStatus.ONLINE, origin: _contact._id })
          );
          const _contactActive = _response.payload as IUser;
          if (typeof _contactActive?._id !== "undefined") {
            dispatch(setupsocket({ _id: _contactActive._id }));
          }
          return _contactActive;
        }
        return null;
      }
      return null;
    };

    e.preventDefault();
    if (nameHasError || emailHasError || helpHasError) return;
    if (
      (email.length === 0 ||
        !validNumberLength({ value: phone?.replace(/[^0-9.]/g, "") })) &&
      // phone?.replace(/[^0-9.]/g, "").length < 12
      name.length === 0 &&
      help.length === 0
    )
      return;

    try {
      const _contact = await _createContact();
      if (_contact && _contact._id) {
        dispatch(authenticationWidgetContact());
        dispatch(reselectUser({ ..._contact, status: UserStatus.ONLINE }));

        if (isOffline) {
          if (
            typeof selectedSettings?.offlinemessage?._id !== "undefined" &&
            typeof selectedSettings?.offlinemessage?.message !== "undefined"
          ) {
            await offlinemessagesService.register({
              offlinemessage: selectedSettings?.offlinemessage?._id,
              user: _contact._id,
              message: help,
              offlinecontent: selectedSettings?.offlinemessage?.message,
              team: teamId,
            });
          }
          setIsLoading(false);
          navigate("/unavailableagents");
        } else {
          const _payload = await _getDefaultTeam(_contact._id);
          if (_payload) {
            dispatch(reselectUser({ ..._contact, status: UserStatus.ONLINE }));
          }
        }
      } else {
        navigate("/error");
      }
    } catch (error) {
      // console.log("error", error);
    }
  };

  if (isLoadingAuth || isLoading)
    return <LoadingDots className="flex my-auto justify-center items-center" />;
  return (
    <Form
      className="bg-white h-[500px] rounded-xl flex flex-col"
      onSubmit={onSubmitHandler}
    >
      <Form.Input
        // icon="user"
        // iconPosition="left"
        label={t("form.label.name")}
        required
        value={name}
        onChange={nameChangeHandler}
        onBlur={nameBlurHandler}
        error={nameHasError}
        type="text"
        name="name"
        id="name"
        variant="outlined"
        placeholder={t("form.placeholder.name")}
        autoComplete="off"
      />
      {nameHasError ? (
        <ErrorLabel error={nameHasError} label={t("form.error.name")} />
      ) : null}
      <Form.Input
        className={nameHasError ? "pt-2" : ""}
        icon="at"
        iconPosition="left"
        label={t("form.label.email")}
        required
        value={email}
        onChange={emailChangeHandler}
        onBlur={emailBlurHandler}
        error={emailHasError}
        type="email"
        name="email"
        id="email"
        variant="outlined"
        placeholder={t("form.placeholder.email")}
        autoComplete="new-email"
      />
      {emailHasError ? (
        <ErrorLabel error={emailHasError} label={t("form.error.email")} />
      ) : null}
      <div className={emailHasError ? "pt-2" : ""} />
      {/* <PhoneMaskInput
        ddi={55}
        error={
          phone?.replace(/[^0-9.]/g, "").length > 0 &&
          phone?.replace(/[^0-9.]/g, "").length < 12
        }
        required
        defaultValue={phone}
        onChange={(e) => {
          setPhone(e.target.value);
        }}
      /> */}
      <PhoneFlagInput
        required
        error={
          phone.length === 0 ? false : !validNumberLength({ value: phone })
        }
        defaultValue={phone}
        onChange={(value: string) => {
          setPhone(value);
        }}
      />
      {phone?.replace(/[^0-9.]/g, "").length > 0 &&
      !validNumberLength({ value: phone?.replace(/[^0-9.]/g, "") }) ? (
        <ErrorLabel
          error={
            phone?.replace(/[^0-9.]/g, "").length > 0 &&
            !validNumberLength({ value: phone?.replace(/[^0-9.]/g, "") })
          }
          label={t("form.error.phone")}
        />
      ) : null}
      {selectedSettings?.defaultteam &&
      selectedSettings?.defaultteam.length > 1 ? (
        <Dropdown
          id="dropdown-settings-defaultteam"
          className={
            phone?.replace(/[^0-9.]/g, "").length > 0 &&
            !validNumberLength({ value: phone?.replace(/[^0-9.]/g, "") })
              ? "pt-2 w-full"
              : "w-full"
          }
          loading={
            isLoadingDropdown && focusId === "dropdown-settings-defaultteam"
          }
          label={t("form.label.team") || "form.label.team"}
          fluid
          required
          search
          selection
          options={optionsTeams}
          placeholder={t("form.placeholder.team") || "form.placeholder.team"}
          onOpen={async () => {
            const _optionsTeams: ddOptions[] = [];
            if (!selectedSettings?._id) {
              setIsLoadingDropdown(true);
              const { payload } = await dispatch(
                getSettings({ widget: "widget" })
              );
              setIsLoadingDropdown(false);
              payload.map(
                (team: {
                  _id: string;
                  name: string;
                  active: boolean;
                  deleted: boolean;
                  offline?: boolean;
                }) =>
                  _optionsTeams.push({
                    key: team._id,
                    text: team.name,
                    value: team._id,
                    content: (
                      <div>
                        <span>{team.name}</span>
                        <i
                          className={`${
                            team.offline
                              ? "las la-circle red"
                              : "las la-circle green"
                          }`}
                        />
                      </div>
                    ),
                  })
              );
            } else {
              selectedSettings.defaultteam?.forEach((team) =>
                _optionsTeams.push({
                  key: team._id,
                  text: team.name,
                  value: team._id,
                  content: (
                    <div className="relative flex items-center justify-between">
                      <span>{team.name}</span>
                      <div
                        className={`flex w-[13px] h-[13px] ${
                          !team.offline ? "bg-green" : "bg-red"
                        } rounded-full mr-2 cursor-pointer`}
                      />
                    </div>
                  ),
                })
              );
            }
            setOptionsTeams(_optionsTeams);
          }}
          onFocus={() => {
            setFocusId("dropdown-settings-defaultteam");
          }}
          onBlur={() => {
            setFocusId("");
          }}
          onChange={async (e, { value }) => {
            const _value = value as string;
            const _team = findInObject(
              selectedSettings.defaultteam,
              "_id",
              _value
            );
            if (typeof _team?.offline !== "undefined" && setIsOffline) {
              setIsOffline(_team.offline);
            }
            if (typeof value === "string") setTeamId(_value);
          }}
        />
      ) : null}
      {isOffline ? (
        <div
          className={`mb-2 ${
            selectedSettings?.defaultteam?.length === 1
              ? "flex items-center justify-center"
              : ""
          }`}
        >
          <ErrorLabel
            error={isOffline}
            pointing={false}
            warning
            label={
              typeof _message?.content !== "undefined"
                ? _message?.content
                : t("form.warning.team")
            }
          />
        </div>
      ) : null}
      <OfficehoursLink
        url={`${process.env.REACT_APP_FRONTEND_URL}/schedule/officehours/${tenantID}`}
      />
      <Form.TextArea
        label={t("form.label.help")}
        placeholder={t("form.placeholder.help")}
        required
        className="bg-none flex flex-col flex-1 border-0 h-[100px] resize-none text-[14px] outline-none"
        value={help}
        onChange={helpChangeHandler}
        onBlur={helpBlurHandler}
        error={helpHasError}
        autoComplete="off"
      />
      {helpHasError ? (
        <div className="flex mb-6">
          <ErrorLabel error={helpHasError} label={t("form.error.help")} />
        </div>
      ) : null}
      <FormButton
        className="flex flex-wrap justify-center mt-2"
        disabled={
          name.length === 0 ||
          help.length === 0 ||
          (selectedSettings?.defaultteam &&
            selectedSettings?.defaultteam.length > 1 &&
            teamId.length === 0) ||
          email.length === 0 ||
          phone?.replace(/[^0-9.]/g, "").length < 12 ||
          nameHasError ||
          emailHasError ||
          helpHasError ||
          false
        }
        label={
          isOffline
            ? t("formbutton.label.register")
            : t("formbutton.label.start")
        }
      />
      <TermsAndPolices widgetType={widgetType} />
    </Form>
  );
};

LoginContactForm.defaultProps = {
  widgetType: UserType.CONTACT,
  isOffline: false,
  setIsOffline: undefined,
};

export default LoginContactForm;
